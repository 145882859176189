/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    CASHDRO_REFUND_AMOUNT,
    CHANGEMACHINE_LIVE_CHECK,
    CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT,
    CHANGEMACHINE_PAYMENT_NOTE_REMOVED,
    CHANGEMACHINE_PAYMENT_WAIT_REMOVE_NOTE,
    CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY,
    CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT,
    MAX_RETRY_ATTAINT,
} from "@constants";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import moment from "moment";
import { snapshot } from "valtio";

import { store } from "@store";

import { syncStore } from "@components/Synchronization/store";

import {
    kioskStore,
    setIsButtonClickedOnCBPaymentMode,
    setIsCreationVoucherCanceled,
    setIsPaymentRefused,
    setIsRegulationModesModalOpened,
    setIsTransactionCanceled,
    setNavigationIndex,
    setTotalAmountDeposited,
} from "@pages/Kiosk/store";
import {
    setAmountDeposited,
    setCashMachineStatus,
    setRenderAmount,
    setVoucherAmount,
} from "@pages/Kiosk/store/actions";

import {
    getPreviousSkippedStepNumber,
    putOrderInsertionRefused,
} from "./general";

export async function refundAmountCashMachine(): Promise<any> {
    const { peripheralShopApp, machineIpAddress, selectedShopApp } =
        snapshot(syncStore);
    const {
        selectedRegulationModeValue,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        amountDeposited,
        cashMachineStatus,
    } = snapshot(kioskStore);
    const timestamp = new Date().getTime();

    const cashMachine: any = peripheralShopApp?.cash_managements;
    const cashMachineConfig: any = cashMachine
        ? Object.values(cashMachine)
        : [];
    const cashMachineIpAddress = cashMachineConfig[0]?.ip;
    const cashMachineApiUrl = cashMachineConfig[0]?.cashMachineApiUrl;
    const cashMachineMark = cashMachineConfig[0]?.mark;

    if (amountDeposited > 0) {
        let url;
        let bodyPaymentData;
        if (cashMachineMark === "Cashdro") {
            const userCashDro = cashMachineConfig[0]?.user;
            const passCashDro = cashMachineConfig[0]?.password;
            const userPos = "USER_KIOSCK_AUTO";
            const posId = cashMachineConfig[0]?.pos_id;

            url = `http://${cashMachineApiUrl}:5011/changeOperationCashdro?timestamp=${timestamp}`;
            bodyPaymentData = {
                cashMachineIpAddress,
                clientIP: machineIpAddress,
                amount: amountDeposited,
                userCashDro,
                passCashDro,
                userPos,
                typeOperation: CASHDRO_REFUND_AMOUNT,
                posId,
            };
        } else {
            url = `http://${cashMachineApiUrl}:5011/changeOperation?timestamp=${timestamp}`;
            bodyPaymentData = {
                cashMachineIpAddress,
                clientIP: machineIpAddress,
                amount: -amountDeposited,
                userId: selectedShopApp.name,
                userPwd: selectedShopApp.passcode,
            };
        }
        return await fetchEventSource(url, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(bodyPaymentData),
            async onopen(response) {
                console.log(
                    "Respone on open payment transaction:",
                    JSON.stringify(response)
                );
                if (response.ok && response.status === 200) {
                    return; // everything's good
                } else if (
                    response.status >= 400 &&
                    response.status < 500 &&
                    response.status !== 429
                ) {
                    throw new Error(`error status ${response.status}`);
                } else {
                    throw Error();
                }
            },
            async onmessage(event) {
                console.log(
                    JSON.stringify({ event: event.event, data: event.data })
                );
                switch (event.event) {
                    case "result": {
                        const code = JSON.parse(event.data).code;

                        switch (code) {
                            case CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT: {
                                console.log(
                                    `CASHMACHINE: Successfully refund deposited amount with total ${
                                        JSON.parse(event.data).response
                                            .returnedAmount
                                    }`
                                );

                                break;
                            }
                            case CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT: {
                                setAmountDeposited(0);
                                if (!cashMachineStatus.isRefundProblem) {
                                    setNavigationIndex(
                                        navigationIndex -
                                            Number(
                                                await getPreviousSkippedStepNumber(
                                                    kioskWays,
                                                    navigationIndex,
                                                    isAuthenticationAccepted,
                                                    isUserAuthenticated,
                                                    selectedSaleModeValue,
                                                    selectedInformationMode,
                                                    isOrderPaidWithLoyaltyAccountBalance,
                                                    selectedRegulationModeValue
                                                )
                                            )
                                    );
                                }
                                break;
                            }
                            case CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY: {
                                // Refuse voucher and cash machine did'nt return deposit amount
                                // Need to create voucher with total deposit amount
                                setVoucherAmount(amountDeposited);
                                setCashMachineStatus({
                                    isNotAvailable: false,
                                    isBillRefund: false,
                                    isRefund: false,
                                    isOccupied: false,
                                    isNetworkLost: false,
                                    isOpened: true,
                                    isRefundProblem: true,
                                    isNetworkProblem: false,
                                    isRefundProblemonAbort: false,
                                });
                                return true;
                            }
                        }
                        break;
                    }
                    case "usermessage": {
                        const eventMessage = JSON.parse(event.data).message;
                        switch (eventMessage.code) {
                            case CHANGEMACHINE_PAYMENT_WAIT_REMOVE_NOTE:
                                setRenderAmount(amountDeposited);
                                setCashMachineStatus({
                                    isNotAvailable: false,
                                    isRefundProblem: false,
                                    isRefundProblemonAbort: false,
                                    isRefund: false,
                                    isOccupied: false,
                                    isNetworkLost: false,
                                    isNetworkProblem: false,
                                    isOpened: true,
                                    isBillRefund: true,
                                });
                                console.log(
                                    "waiting to remove notes or coins detected to open modal"
                                );
                                break;
                            case CHANGEMACHINE_PAYMENT_NOTE_REMOVED:
                                setRenderAmount(0);
                                setCashMachineStatus({
                                    isNotAvailable: false,
                                    isRefundProblem: false,
                                    isRefundProblemonAbort: false,
                                    isRefund: false,
                                    isOccupied: false,
                                    isNetworkLost: false,
                                    isOpened: false,
                                    isBillRefund: false,
                                    isNetworkProblem: false,
                                });
                                console.log(
                                    "removed notes or coins detected to close modal"
                                );
                                break;
                            case CHANGEMACHINE_LIVE_CHECK:
                                console.log(
                                    `is cash machine a live: ${eventMessage.isAlive}`
                                );
                                setCashMachineStatus({
                                    isNotAvailable: false,
                                    isRefundProblem: false,
                                    isRefundProblemonAbort: false,
                                    isRefund: false,
                                    isOccupied: false,
                                    isNetworkProblem: false,
                                    isBillRefund: false,
                                    isOpened: !eventMessage.isAlive,
                                    isNetworkLost: !eventMessage.isAlive,
                                });
                                break;
                            default:
                                console.log(
                                    "unxpected code on user message for cash machine with code: ",
                                    eventMessage
                                );
                                break;
                        }
                    }
                }
            },
            onclose() {
                console.log("connection close in payment API");
                return false;
            },
            onerror(error) {
                console.log(
                    `Error during the payment, with the message: ${error}`
                );

                console.log("Cash Machine payment error or refused");
                setCashMachineStatus({
                    isBillRefund: false,
                    isRefund: false,
                    isRefundProblem: false,
                    isRefundProblemonAbort: false,
                    isOccupied: false,
                    isNetworkLost: false,
                    isOpened: true,
                    isNotAvailable: true,
                    isNetworkProblem: false,
                });
                throw error;
            },
        });
    }

    return false;
}

export async function handleCreateVoucher(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    middlwareOrder: any
): Promise<boolean> {
    let retryCounter = 0;
    let isVoucherCreated = false;

    const { voucherAmount } = snapshot(kioskStore);
    const { middlewareApiUrl } = snapshot(store);

    const voucher = {
        echeance: moment(new Date().setMonth(new Date().getMonth() + 1)).format(
            "YYYY-MM-DD"
        ),
        montant: voucherAmount,
        mode_reglement: "ESP",
        numero_commande: "",
    };

    console.log(
        "handleCreateVoucher func, the voucher:",
        JSON.stringify(voucher)
    );
    const apiUrl = `${middlewareApiUrl}/PutBonAvoir?timestamp=${new Date().getTime()}`;
    console.log("API URL:", apiUrl);

    const putVoucher = async (): Promise<void> => {
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(voucher),
            });

            const result = await response.json();
            console.log("Voucher API response:", JSON.stringify(result));

            if (result.code === 2) {
                console.log(`Voucher with amount ${voucher.montant} created`);
                handlePrintVoucher(result.id_avoir);
                console.log(
                    "Cash machine voucher printed with id",
                    result.id_avoir
                );
                isVoucherCreated = true;
            } else {
                console.log(
                    "Response code error. Retry counter:",
                    retryCounter
                );
                retryCounter += 1;
                if (retryCounter < MAX_RETRY_ATTAINT) {
                    await putVoucher(); // Retry
                } else {
                    putOrderInsertionRefused(middlwareOrder);
                }
            }
        } catch (error) {
            console.error(`Error while inserting the voucher: ${error}`);
            console.log(
                "Error on creating voucher. Retry counter:",
                retryCounter
            );
            retryCounter += 1;
            if (retryCounter < MAX_RETRY_ATTAINT) {
                await putVoucher(); // Retry
            } else {
                putOrderInsertionRefused(middlwareOrder);
            }
        }
    };

    await putVoucher();

    return isVoucherCreated;
}

async function handlePrintVoucher(voucherId: string) {
    const timestamp = new Date().getTime();
    const {
        isCommandInsertionProblemModalOpened,
        voucherAmount,
        returnedAmount,
        isEventStreamProblem,
        isCreationVoucherCanceled,
        isCashMachineTransactionCanceled,
    } = snapshot(kioskStore);
    const { middlewareApiUrl } = snapshot(store);
    console.log(
        "handlePrintVoucher with the ID:",
        { voucherId },
        "the launched api:",
        `${middlewareApiUrl}/PrintBonAvoir?IdAvoir=${voucherId}`
    );
    fetch(
        `${middlewareApiUrl}/PrintBonAvoir?IdAvoir=${voucherId}&timestamp=${timestamp}`
    )
        .then((response) => response.json())
        .then(async (result) => {
            console.log(
                "the response of printing a voucher:",
                JSON.stringify(result)
            );
            if (result.code === 2) {
                console.log("The voucher is printed successfully!!");
                if (isCashMachineTransactionCanceled) {
                    console.log(
                        "The cash machine transaction is canceled after a timeout and the change machine cannot return the deposited amount, so this voucher replace the amount deposited"
                    );
                    setNavigationIndex(0);
                } else {
                    console.log(
                        `A voucher with the amount: ${
                            isCommandInsertionProblemModalOpened
                                ? returnedAmount
                                : voucherAmount
                        } has been printed successfully`
                    );
                    console.log(
                        "In handle print voucher function:",
                        JSON.stringify({
                            isCreationVoucherCanceled,
                            isEventStreamProblem,
                        })
                    );

                    setIsCreationVoucherCanceled(false);
                    setTotalAmountDeposited(0);
                }
            }
        })
        .catch((error) => {
            console.error(
                `Error while printing the voucher, with the message:${error}`
            );
        });
}

export function initConnectionCashMAchine(): void {
    const { selectedShopApp, peripheralShopApp, machineIpAddress } =
        snapshot(syncStore);
    const {
        ip: cashMachineIpAddress,
        port_number: cashMachinePort,
        cashMachineApiUrl,
    } = Object.values(peripheralShopApp.cash_managements as any)[0] as any;
    fetch(`http://${cashMachineApiUrl}:5011/initConnection`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            cashMachineIpAddress,
            cashMachinePort,
            clientIP: machineIpAddress,
            userId: selectedShopApp.name,
            userPwd: selectedShopApp.passcode,
        }),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            return response.json();
        })
        .then((data) => {
            console.log("Success on init cash machine connection:", data);
        })
        .catch((error) => {
            console.error("Error on init cash machine connection:", error);
        });
}

export async function handleCashMachineNavigationAfterTransactionCanceled(): Promise<void> {
    const {
        selectedRegulationModeValue,
        kioskWays,
        navigationIndex,
        isAuthenticationAccepted,
        isUserAuthenticated,
        selectedSaleModeValue,
        selectedInformationMode,
        isOrderPaidWithLoyaltyAccountBalance,
        isButtonClickedOnCBPaymentMode,
    } = snapshot(kioskStore);

    console.log(
        "cash machine transaction cancelled with voucher amount",
        isButtonClickedOnCBPaymentMode.isAnotherPaymentModeButtonClicked
    );

    console.log(
        "isButtonClickedOnCBPaymentMode",
        isButtonClickedOnCBPaymentMode
    );

    setCashMachineStatus({
        isNotAvailable: false,
        isBillRefund: false,
        isRefund: false,
        isOccupied: false,
        isNetworkLost: false,
        isOpened: false,
        isRefundProblem: false,
        isNetworkProblem: false,
        isRefundProblemonAbort: false,
    });

    if (isButtonClickedOnCBPaymentMode.isAnotherPaymentModeButtonClicked) {
        console.log(
            "Successfully open regulation mode after transaction aborted on cash machine cancel with voucher"
        );
        setIsRegulationModesModalOpened(true);
        setIsButtonClickedOnCBPaymentMode({
            isAnotherPaymentModeButtonClicked: false,
            isBackPaymentModeButtonClicked: false,
        });
        return;
    }

    setIsButtonClickedOnCBPaymentMode({
        isAnotherPaymentModeButtonClicked: false,
        isBackPaymentModeButtonClicked: false,
    });
    setIsRegulationModesModalOpened(false);
    setNavigationIndex(
        navigationIndex -
            Number(
                await getPreviousSkippedStepNumber(
                    kioskWays,
                    navigationIndex,
                    isAuthenticationAccepted,
                    isUserAuthenticated,
                    selectedSaleModeValue,
                    selectedInformationMode,
                    isOrderPaidWithLoyaltyAccountBalance,
                    selectedRegulationModeValue
                )
            )
    );
    setIsPaymentRefused(false);
    setIsTransactionCanceled(false);
    setAmountDeposited(0);
}
