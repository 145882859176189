import { TRANSITION_VARIANTS, transitions } from "./transitions";

export {
    CHANGE_MACHINE_ID,
    CREDIT_CRAD_ID,
    RESTOFLASH,
} from "./paymentConstant";

export {
    SCAN_BUTTON_ID,
    ENTER_BUTTON_ID,
    USE_BUTTON_ID,
    SELECT_BUTTON_ID,
    LOGOUT_BUTTON_ID,
    GODIA_LOYALTY,
    CESAR_LOYALTY,
} from "./loyaltyConsummation";

const REDIRECTION = "isRedirect";
const ETK_CESAR = 3;
const ETK_STANDARD = 1;

const COMPOSITIONS = "compositions";
const ALLERGENS = "allergènes";
const BASIC_COMPOSITION = "basic_composition";
const IS_NEXT_IN_LIST = "isNext";
const ISCHEAPEST = "isCheapest";
const ISEXPENSIVE = "isCheapest";
const NO_ThANKS_CARD = "noThanks";
const IS_NEXT_CARD = "isNext";
const ALLERGENS_OPTION = "Allergen";
const ACCOUNT_OPTION = "Customer account";
const CONNECT_USER_OPTION = "customer account";
const DELIVERY_MODE = "Delivery";
const EMPTY_BASKET_ICON_ROLE = 1;
const FULL__BASKET_ICON_ROLE = 2;
const OUT_OF_STOCK_ROLE = 3;
const SUSPENDED_ROLE = 9;
const BACK_BUTTON_ROLE = 3;
const WITHOUT_BUTTON = 4;
const DESIGN_NO_THANKS_BUTTON_ROLE = 5;
const DESIGN_IS_NEXT_BUTTON_ROLE = 6;
const BASIC_COMPOSITION_TYPE = "composition de base";
const CATEGORY_TYPE = "categories";
const ITEM_TYPE = "items";
const STEP_TYPE = "steps";
const SPECIAL_PRICE_TYPE = 1;
const STEP_PRICE_TYPE = 2;
const ITEM_PRICE_TYPE = 3;
const VALIDATE_BUTTON_ROLE = 1;

const PAY_BUTTON_ROLE = 8;
const PRM_PAY_BUTTON_ROLE = 9;
const CANCEL_BUTTON_ROLE = 10;
const PRM_CANCEL_BUTTON_ROLE = 11;
const VALIDATE_IN_STEP_BUTTON_ROLE = 12;
const NEXT_IN_STEP_BUTTON_ROLE = 13;
const PREVIOUS_IN_STEP_BUTTON_ROLE = 14;
const ABANDON_BUTTON_ROLE = 15;
const CONTINUE_ORDER_BUTTON_ROLE = 16;
const NO_THANKS_BUTTON_ROLE = 17;
const YES_BUTTON_ROLE = 18;
const NO_BUTTON_ROLE = 19;
const ADD_TO_ORDER_BUTTON_ROLE = 20;
const PREVIOUS_BUTTON_ROLE = 21;
const NEXT_BUTTON_ROLE = 22;
const PRM_BUTTON = "prm/child";
const ASK_FOR_HELP_BUTTON = "ask for help";
const VALID_IN_STEP = "valid in step";
const NEXT_IN_STEP = "next in step";
const PREVIOUS_IN_STEP = "previous in step";
const CESAR_CATLOGUE_TYPE = 3;
const USUAL_MODE = "usual";
const IP_ADDRESS_REGEX = /^(?:localhost|(?:\d{1,3}\.){3}\d{1,3})$/;
const FIXED_COMPOSITION_TYPE = "composition_fixe";

const REQUEST_TIMEOUT_TCPOS = 30000;
const ORDER_VALDATION__TIMEOUT_TCPOS = 720000;
const SEND_NORMAL_TICKET = 0;
const SEND_MAIL_WITH_SMALL_TICKET = 1;
const SEND_ONLY_SMALL_TICKET = 2;
//Payment Constants
const PAYMENT_REFUSED = 0;
const PAYMENT_ABORTED = 1;
const PAYMENT_TIMEOUT = 3;
const CASH_MACHINE_OCCUPIED = 5;
const CASH_MACHINE_NETWORK_ERROR = -1;
const VALIDE_PAYMENT_WITH_CHANGEMACHINE = 1001;
const CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT = 1002;
const CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT = 1003;
const CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY = 999;
const ABORT_WITH_VOUCHER = 199;
const CHANGEMACHINE_PAYMENT_DEPOSITE = 6;
const CHANGEMACHINE_PAYMENT_DISPENSE = 7;
const CHANGEMACHINE_LIVE_CHECK = 66;
const CHANGEMACHINE_PAYMENT_WAIT_REMOVE_NOTE = 8;
const CHANGEMACHINE_PAYMENT_NOTE_REMOVED = 9;
const CASHDRO_INSERT_AMOUNT = 4;
const CASHDRO_REFUND_AMOUNT = 3;
const CASHDRO_TRANSACTION_ID = 100;

const VALINA_PAYMENT_DEFAULT_MODE = 1;
const VALINA_PAYMENT_WITHOUT_CONTACT = 3;
const VALINA_PAYMENT_WITH_CONTACT = 4;

const VALIDATED_EXTENSION_FILE = [".png", "webp", ".jpg"];
const SKIPPED_PAGES = ["authenticationModes", "salesMethods", "meansOfPayment"];
const STEP_PAYEMENT = "payment";

const ISNEPTING_TYPE_46_OR_45 = 1;
const ISNEPTING_TYPE_10 = 2;
// Clients Partner List
const TCPOS = "TCPOS";
// const Zelty = "Zelty";
// const Crisalid = "Crisalid";
// const Cegid = "Cegid";
const CESAR = "Cesar";
const CASHPAD = "Cashpad";
// const Synapsy = "Synapsy";
// const Merim = "Merim";
const CLICK_AND_COLLECT_ITEM_NAME = "Retrait C&C";
const SALES_METHODS_STEP_NAME = "salesMethods";

const KIOSK_AUTHORIZATION_ID = "a60a2f53-9d31-4bf7-a0e5-9fda2fce797a";
const SEND_EMAIL = "envoyer";
const NOT_SEND_EMAIL = "nepasenvoyer";
const YES = "oui";
const NO = "non";
const KioskAssetsSize = {
    "1_1": { height: 600, width: 800, url: "_1_1" },
    "2_1": { height: 518, width: 691, url: "_2_2" },
    "2_2": { height: 310, width: 414, url: "_2_2" },
    "1_2": { height: 310, width: 414, url: "_2_2" },
    "3_2": { height: 310, width: 414, url: "_3_3" },
    "3_3": { height: 212, width: 283, url: "_3_3" },
    "3_1": { height: 212, width: 283, url: "_3_3" },
    "1_3": { height: 212, width: 283, url: "_3_3" },
    "2_3": { height: 212, width: 283, url: "_3_3" },
    "4_3": { height: 212, width: 283, url: "_4_4" },
    "4_4": { height: 159, width: 212, url: "_4_4" },
    "3_4": { height: 159, width: 212, url: "_4_4" },
    "1_4": { height: 159, width: 212, url: "_4_4" },
    "2_4": { height: 159, width: 212, url: "_4_4" },
    "4_1": { height: 159, width: 212, url: "_4_4" },
    "4_2": { height: 159, width: 212, url: "_4_4" },
    "5_4": { height: 159, width: 212, url: "_1_1" },
    "5_5": { height: 127, width: 170, url: "_5_5" },
    "1_5": { height: 127, width: 170, url: "_5_5" },
    "2_5": { height: 127, width: 170, url: "_5_5" },
    "3_5": { height: 127, width: 170, url: "_5_5" },
    "4_5": { height: 127, width: 170, url: "_5_5" },
    "5_1": { height: 127, width: 170, url: "_5_5" },
    "5_2": { height: 127, width: 170, url: "_5_5" },
    "5_3": { height: 127, width: 170, url: "_5_5" },
};
// Synchronization Stepper Constants

const SETTING_SYNCRO_STEP = 2;
const CATALOG_SYNCRO_STEP = 3;
const COMPLETED_SYNCRO_STEP = 4;
const FINAL_SYNCHRO_STEP = 5;

// type of constants for type of kiosk
const ELECTRON_MODE = "1";
const WEBVIEW_MODE = "2";
const CATALOGUE_MODE = "3";
const DEV_MODE = "4";

const Retrait_C_C = "Retrait C&C";
const IS_WORKFLOW_TYPE = "isWorkflowType";
const IS_ADDITIONAL_TYPE = "isAdditionnalSaleType";
const IS_GENERIC_ADDITIONAL_SALE = "string";

const POSSIBLE_EXTENSIONS = ["mp4", "mov", "avi", "flv", "wmv", "webm"];

const AGENT_LOCAL_API_URL = "http://127.0.0.1:6009/api/v1";

const LICENCE_PROBLEM = "EC 001";
const FENCE_PROBLEM = "EC 002";
const NETWORK_PROBLEM = "EC 003";

const KIOSK_PROBLEM = "EC 101";

const NO_CATEGORIES_ENABLED_FOR_SALESMODE = "EC 201";
const GETING_CARD_CATALOGUE_PROBLEM = "EC 202";

const FENCE_PROBLEM_TCPOS = "EC 401";
const ADDING_PROLEM_TCPOS = "EC 402";
const DELETED_PROBLEM_TCPOS = "EC 403";
const REFRESH_PROBLEM_TCPOS = "EC 404";
const CANCELLATION_PROBLEM_TCPOS = "EC 405";
const ABORT_ITEM = "Abandon article";
const CANCEL_BUTTON = "Back";
const IS_TOAST_ENABLE = "true";
const MAX_RETRY_ATTAINT = 2;
const DEFAULT_PASSCODE = "0360";

const PERCENT_ON_ORDER = 1;
const FREE_AMOUNT_ON_ORDER = 2;
const FREE_AMOUNT_ON_PRODUCT = 3;
const PERCENT_ON_PRODUCT = 4;
const FREE_DELIVRY = 5;
const FREE_PRODUCT = 6;

const RESPONSE_CODE_SUCCESS = 2;
const RESPONSE_CODE_FAILED = 0;

const KIOSK_ACCOUNT_CREATOR = 2;

const DEFAULT_COUNTRY_CODE_FR = "FR";

const MAX_DIGITS_FRANCE = 10;

const CREATE_AN_ACCOUNT_ID = "4a9de408-6c96-479d-9859-b5f02459f5c9";

const INITIAL_VALUES = {
    phoneNumber: "",
    firstName: "",
    name: "",
    email: "",
    country: "",
    postalCode: "",
};

const INITIAL_USER: UserType = {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    loyaltyAccount: 0,
    loyaltyAmount: 0,
    loyaltyType: "",
    amountRatio: 0,
    pointRatio: 0,
};

export {
    ALT_LAYAOUT,
    AZERTY_KEYBOARD,
    DISPLAY_LAYAOUT,
    EN_LAYAOUT,
    EN_SHIFT_LAYAOUT,
    FR_LAYAOUT,
    FR_SHIFT_LAYAOUT,
    NUMERIC_LAYAOUT,
} from "./keyboardLayout";

export {
    CASH_MACHINE_NETWORK_ERROR,
    TRANSITION_VARIANTS,
    AGENT_LOCAL_API_URL,
    KIOSK_PROBLEM,
    GETING_CARD_CATALOGUE_PROBLEM,
    LICENCE_PROBLEM,
    FENCE_PROBLEM,
    NO_CATEGORIES_ENABLED_FOR_SALESMODE,
    NETWORK_PROBLEM,
    FENCE_PROBLEM_TCPOS,
    ADDING_PROLEM_TCPOS,
    DELETED_PROBLEM_TCPOS,
    REFRESH_PROBLEM_TCPOS,
    CANCELLATION_PROBLEM_TCPOS,
    CATALOGUE_MODE,
    WEBVIEW_MODE,
    ELECTRON_MODE,
    DEV_MODE,
    REDIRECTION,
    BASIC_COMPOSITION,
    PAY_BUTTON_ROLE,
    PRM_PAY_BUTTON_ROLE,
    CANCEL_BUTTON_ROLE,
    PRM_CANCEL_BUTTON_ROLE,
    VALIDATE_IN_STEP_BUTTON_ROLE,
    NEXT_IN_STEP_BUTTON_ROLE,
    PREVIOUS_IN_STEP_BUTTON_ROLE,
    ABANDON_BUTTON_ROLE,
    CONTINUE_ORDER_BUTTON_ROLE,
    NO_THANKS_BUTTON_ROLE,
    YES_BUTTON_ROLE,
    NO_BUTTON_ROLE,
    ADD_TO_ORDER_BUTTON_ROLE,
    NEXT_BUTTON_ROLE,
    PREVIOUS_BUTTON_ROLE,
    EMPTY_BASKET_ICON_ROLE,
    FULL__BASKET_ICON_ROLE,
    OUT_OF_STOCK_ROLE,
    DESIGN_NO_THANKS_BUTTON_ROLE,
    DESIGN_IS_NEXT_BUTTON_ROLE,
    BACK_BUTTON_ROLE,
    PRM_BUTTON,
    ASK_FOR_HELP_BUTTON,
    WITHOUT_BUTTON,
    BASIC_COMPOSITION_TYPE,
    IS_NEXT_IN_LIST,
    ISCHEAPEST,
    ISEXPENSIVE,
    NO_ThANKS_CARD,
    IS_NEXT_CARD,
    COMPOSITIONS,
    ALLERGENS,
    CATEGORY_TYPE,
    ITEM_TYPE,
    STEP_TYPE,
    ALLERGENS_OPTION,
    ACCOUNT_OPTION,
    SPECIAL_PRICE_TYPE,
    STEP_PRICE_TYPE,
    ITEM_PRICE_TYPE,
    VALID_IN_STEP,
    NEXT_IN_STEP,
    PREVIOUS_IN_STEP,
    DELIVERY_MODE,
    VALIDATE_BUTTON_ROLE,
    CESAR_CATLOGUE_TYPE,
    USUAL_MODE,
    ETK_CESAR,
    IP_ADDRESS_REGEX,
    FIXED_COMPOSITION_TYPE,
    ETK_STANDARD,
    TCPOS,
    CASHPAD,
    REQUEST_TIMEOUT_TCPOS,
    ORDER_VALDATION__TIMEOUT_TCPOS,
    PAYMENT_REFUSED,
    VALIDE_PAYMENT_WITH_CHANGEMACHINE,
    CHANGEMACHINE_PAYMENT_WITH_RETURNED_AMOUNT,
    CHANGEMACHINE_PAYMENT_WITH_REFUND_ANOMALY,
    ABORT_WITH_VOUCHER,
    CHANGEMACHINE_PAYEMENT_WITHOUT_RETURN_OR_CREDITCARD_PAYMENT,
    VALINA_PAYMENT_WITHOUT_CONTACT,
    VALINA_PAYMENT_WITH_CONTACT,
    SUSPENDED_ROLE,
    VALIDATED_EXTENSION_FILE,
    SEND_NORMAL_TICKET,
    SEND_MAIL_WITH_SMALL_TICKET,
    SEND_ONLY_SMALL_TICKET,
    transitions,
    SKIPPED_PAGES,
    CESAR,
    CONNECT_USER_OPTION,
    STEP_PAYEMENT,
    CLICK_AND_COLLECT_ITEM_NAME,
    SALES_METHODS_STEP_NAME,
    KIOSK_AUTHORIZATION_ID,
    SEND_EMAIL,
    NOT_SEND_EMAIL,
    YES,
    NO,
    KioskAssetsSize,
    SETTING_SYNCRO_STEP,
    CATALOG_SYNCRO_STEP,
    COMPLETED_SYNCRO_STEP,
    FINAL_SYNCHRO_STEP,
    Retrait_C_C,
    ISNEPTING_TYPE_46_OR_45,
    ISNEPTING_TYPE_10,
    POSSIBLE_EXTENSIONS,
    IS_WORKFLOW_TYPE,
    IS_ADDITIONAL_TYPE,
    IS_GENERIC_ADDITIONAL_SALE,
    VALINA_PAYMENT_DEFAULT_MODE,
    PAYMENT_ABORTED,
    PAYMENT_TIMEOUT,
    CASH_MACHINE_OCCUPIED,
    CANCEL_BUTTON,
    ABORT_ITEM,
    IS_TOAST_ENABLE,
    MAX_RETRY_ATTAINT,
    DEFAULT_PASSCODE,
    PERCENT_ON_ORDER,
    FREE_AMOUNT_ON_ORDER,
    FREE_AMOUNT_ON_PRODUCT,
    PERCENT_ON_PRODUCT,
    FREE_DELIVRY,
    FREE_PRODUCT,
    CHANGEMACHINE_PAYMENT_NOTE_REMOVED,
    CHANGEMACHINE_PAYMENT_WAIT_REMOVE_NOTE,
    CHANGEMACHINE_PAYMENT_DEPOSITE,
    CHANGEMACHINE_PAYMENT_DISPENSE,
    CHANGEMACHINE_LIVE_CHECK,
    RESPONSE_CODE_SUCCESS,
    RESPONSE_CODE_FAILED,
    KIOSK_ACCOUNT_CREATOR,
    DEFAULT_COUNTRY_CODE_FR,
    INITIAL_VALUES,
    MAX_DIGITS_FRANCE,
    CASHDRO_REFUND_AMOUNT,
    CASHDRO_INSERT_AMOUNT,
    CASHDRO_TRANSACTION_ID,
    CREATE_AN_ACCOUNT_ID,
    INITIAL_USER,
};
