import {
    ACCOUNT_OPTION,
    ALLERGENS_OPTION,
    BASIC_COMPOSITION,
    BASIC_COMPOSITION_TYPE,
    CATALOGUE_MODE,
    CATEGORY_TYPE,
    ITEM_TYPE,
    STEP_TYPE,
} from "@constants";
import { produce } from "immer";
import { set } from "lodash";
import { snapshot } from "valtio";

import { getFilenameFromUrl } from "@helpers/general";

import {
    getItemPriceWithOption,
    getPrice,
    getSuspendedItem,
} from "@components/OrderTaking/Helpers";

import { kioskStore } from "@pages/Kiosk/store";

export const initializePopup = (
    p,
    setProduct,
    setIsOpenProductType,
    setIsOpenProductComposition
) => {
    if ("property" in p) {
        setProduct(p);
        setIsOpenProductType(true);
    } else if (BASIC_COMPOSITION in p) {
        setProduct(p);
        setIsOpenProductComposition(true);
    }

    return false;
};

export const productPropertiesHandler = (product, properties) => {
    const propertiesArray = product.property.map((item) => {
        if (item.id == properties.id) {
            return properties;
        } else {
            return item;
        }
    });
    return propertiesArray;
};

/**
 *
 * @param {string} hex
 * @param {number} opacity
 *
 * @returns string
 */
export function hexToRGBWithModifiedOpacity(hex, opacity = undefined) {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    if (opacity !== undefined) {
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    } else {
        return `rgb(${r}, ${g}, ${b})`;
    }
}
export function setObj(obj, path, val) {
    const keys = path.split("#");
    const lastKey = keys.pop();
    const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
    lastObj[lastKey] = val;
}

export const modifyNestedObject = (parent, child, path) => {
    return produce(parent, (draftState) => {
        set(draftState.shopCart, path, child);
    });
};

export const getOrdertakingOptions = (activeConfigOptions, ordertaking) => {
    return activeConfigOptions.reduce((prev, cur) => {
        const res = ordertaking.filter(
            (option) =>
                option.title.toLowerCase() === cur.title.toLowerCase() &&
                option.active === true &&
                ![ACCOUNT_OPTION, ALLERGENS_OPTION].includes(option.title)
        );
        if (res?.length > 0) {
            return [...prev, res[0]];
        }

        return [...prev];
    }, []);
};

export const getOrdertakingDesignOptions = (
    activeConfigOptions,
    ordertaking,
    optionsList
) => {
    return activeConfigOptions.reduce((prev, cur) => {
        const res = ordertaking.filter(
            (option) =>
                option.name === cur.title &&
                option.active === true &&
                !optionsList.includes(option.name)
        );
        if (res?.length > 0) {
            return [...prev, res[0]];
        }

        return [...prev];
    }, []);
};

export const getPrmOrdertakingDesignOptions = (
    activeConfigOptions,
    ordertaking
) => {
    return activeConfigOptions.reduce((prev, cur) => {
        const res = ordertaking.filter((option) => {
            return (
                option.title === cur.title &&
                option.active === true &&
                option.title !== ALLERGENS_OPTION
            );
        });
        if (res?.length > 0) {
            return [...prev, res[0]];
        }

        return [...prev];
    }, []);
};

function isVisible(product, salesMode, isStepItem = false) {
    const recheckResult = (productVisibility) => {
        const salesModeIsFound = Object.keys(productVisibility).filter(
            (keySalesMode) => {
                if (
                    salesMode.toLowerCase().includes(keySalesMode.toLowerCase())
                ) {
                    return keySalesMode;
                }
            }
        );

        return salesModeIsFound.length > 0
            ? productVisibility[salesModeIsFound[0]]
            : false;
    };
    if (isStepItem) {
        return product.productVisibility[salesMode]
            ? product.productVisibility[salesMode]
            : recheckResult(product.productVisibility);
    }
    return (product.categoryVisibility &&
        (product.categoryVisibility[salesMode] === true ||
            recheckResult(product.categoryVisibility))) ||
        Object.keys(product.categoryVisibility).length === 0
        ? product.productVisibility[salesMode]
            ? product.productVisibility[salesMode]
            : recheckResult(product.productVisibility)
        : false;
}

export function composedProductVisibility(prod, salesMode) {
    Object.entries(prod.shopCart).map(([stepKey, valueStep]) => {
        const newStep = Object.entries(valueStep.compositions).reduce(
            (prev, [key, value]) => {
                value = { ...value, isDeleted: false };
                if (!isVisible(value, salesMode, true)) {
                    value = { ...value, isDeleted: true };
                }
                return { ...prev, [key]: value };
            },
            {}
        );

        prod.shopCart[stepKey].compositions = newStep;
    });
    return { ...prod };
}

export function deleteComposedProductVisibility(prod, salesMode) {
    Object.entries(prod.shopCart).map(([stepKey, valueStep]) => {
        const newStep = Object.entries(valueStep.compositions).reduce(
            (prev, [key, value]) => {
                if (!isVisible(value, salesMode, true)) {
                    return { ...prev };
                }
                return { ...prev, [key]: value };
            },
            {}
        );

        if (Object.keys(newStep).length !== 0) {
            prod.shopCart[stepKey].compositions = newStep;
        } else {
            delete prod.shopCart[stepKey];
        }
    });
    return { ...prod };
}

export function checkVisibilityNewOrder(orderItems, salesMode) {
    let res = [];
    orderItems.map((item) => {
        item = { ...item, isDeleted: false };
        return isVisible(item, salesMode) === true ||
            !getSuspendedItem(item.suspSale)
            ? Object.keys(item.shopCart).length === 0
                ? res.push(item) //? simple product visibilty = true
                : res.push(composedProductVisibility(item, salesMode)) //? composed product
            : res.push({ ...item, isDeleted: true });
    });
    return res;
}
export function deletedInvisibleNewOrder(orderItems, salesMode) {
    let res = [];
    orderItems?.map((item) => {
        return isVisible(item, salesMode) === true
            ? Object.keys(item.shopCart).length === 0
                ? res.push(item) //? simple product visibilty = true
                : res.push(deleteComposedProductVisibility(item, salesMode)) //? composed product
            : null;
    });

    return res;
}

export function editSalesMode(orderItems, salesModeId) {
    const itemPrice = { totalItemPrice: 0 }; // make this global reference to calculate price for every oreder items

    const updateObject = (keyName, object, parentItem) => {
        for (var key in object) {
            if (key === keyName) {
                let initialItemOption = [salesModeId, "KIOSK"];

                let newPrice = getPrice(
                    initialItemOption,
                    object.advancedItemPrice,
                    true
                );

                if (object.withSpecialPrice) {
                    newPrice.price = parseFloat(object.specialPrice);
                } else {
                    newPrice.price = parseFloat(object.priceOfItemInStep);
                    if (
                        parentItem?.selectedOptions.length > 0 &&
                        object.opt &&
                        Object.keys(object.opt)
                    ) {
                        newPrice = getItemPriceWithOption(
                            parentItem?.selectedOptionsUuid,
                            object.opt,
                            object.advancedItemPrice,
                            newPrice
                        );
                    }
                }

                itemPrice.totalItemPrice += Number(newPrice.price);
                object[key] =
                    newPrice.price !== undefined ? Number(newPrice.price) : 0;

                object["tva"] = object.advancedItemPrice.saleModeVatRates[
                    salesModeId
                ]
                    ? object.advancedItemPrice.saleModeVatRates[salesModeId]
                    : 0;
            } else {
                if (
                    typeof object[key] === "object" &&
                    key !== "workflow" &&
                    key !== BASIC_COMPOSITION_TYPE
                ) {
                    object[key] = updateObject(keyName, object[key]);
                }
            }
        }

        return object;
    };

    const newOrderItems = orderItems.reduce(
        (prevItem, currItem) => {
            itemPrice.totalItemPrice = 0;

            let selectedOptionsUuid = currItem.selectedOptionsUuid;

            selectedOptionsUuid =
                currItem.selectedOptionsUuid.length > 0
                    ? [salesModeId, ...selectedOptionsUuid.slice(1)]
                    : [salesModeId, "KIOSK"];

            const newPrice = getPrice(
                selectedOptionsUuid,
                currItem.advancedItemPrice,
                selectedOptionsUuid.length === 2
            );

            if (Object.keys(currItem.shopCart).length > 0) {
                itemPrice.totalItemPrice = Number(newPrice.price);
                updateObject("price", currItem.shopCart, currItem);
            }

            const modifiedItem = {
                ...currItem,
                price:
                    itemPrice.totalItemPrice > 0
                        ? Number(itemPrice.totalItemPrice)
                        : Number(newPrice.price),

                tva: currItem.advancedItemPrice.saleModeVatRates[salesModeId]
                    ? currItem.advancedItemPrice.saleModeVatRates[salesModeId]
                    : 0,
                defaultPrice: Number(newPrice.price),
                selectedOptionsUuid,
            };

            return [...prevItem, modifiedItem];
        },

        []
    );

    const itemsCount = newOrderItems.reduce(
        (prev, curr) => prev + curr.quantity,
        0
    );

    const itemsPrice = newOrderItems.reduce(
        (a, c) => a + c.quantity * c.price,
        0
    );

    const totalPrice = Math.round(itemsPrice * 100) / 100;

    return { orderItems: newOrderItems, totalPrice, itemsCount };
}
export function getDefaultCategory(saleMode, globalcategories) {
    const result = globalcategories.filter((category) => {
        return (
            (category?.visibilityInfo.isVisible &&
                category?.KioskVisibility[saleMode]) ||
            category?.KioskVisibility[saleMode] === undefined
        );
    });

    return result[0] ? result[0].iuud : Object.keys(globalcategories)[0]?.iuud;
}
export function getProductTitle(name) {
    const { customerLanguage } = snapshot(kioskStore);
    return name?.advancedDisplayName !== undefined &&
        name?.advancedDisplayName?.[customerLanguage.language] !== undefined &&
        name?.advancedDisplayName?.[customerLanguage.language] !== ""
        ? name?.advancedDisplayName?.[customerLanguage.language]
        : name?.content;
}
export function getImageByLanguage(image, type) {
    const { customerLanguage } = snapshot(kioskStore);
    let result =
        image?.[customerLanguage.language] !== undefined
            ? image?.[customerLanguage.language]
            : image?.["default"];
    if (process.env.REACT_APP_MODE === CATALOGUE_MODE) {
        const schema = localStorage.getItem("viewADB-schema");
        switch (type) {
            case CATEGORY_TYPE:
                result = `${process.env.REACT_APP_CATALOGUE_IMAGES}/${schema}/Category/${result}`;
                break;
            case ITEM_TYPE:
                result = `${process.env.REACT_APP_CATALOGUE_IMAGES}/${schema}/Items/${result}`;
                break;
            case STEP_TYPE:
                result = `${process.env.REACT_APP_CATALOGUE_IMAGES}/${schema}/Steps/${result}`;
                break;
            default:
                break;
        }
    }

    return getFilenameFromUrl(result, "catalog", type)
        ? getFilenameFromUrl(result, "catalog", type)
        : "./images/nopicture.png";
}

export const refactorStepMessage = (stepMessages, customerLanguage) => {
    const result = stepMessages?.["1"]?.[customerLanguage.name]
        ? stepMessages?.["1"][customerLanguage.name]
        : "";
    return result;
};
export const updateItemsOutOfStock = (
    redirectItem,
    outOfStockValue,
    keyOutOfStockKey,
    categories,
    categoryParentIuud
) => {
    if (redirectItem !== undefined && categories !== undefined) {
        redirectItem.outOfStock = outOfStockValue;
        categories[categoryParentIuud].listProduct.forEach((el) => {
            if (el.iuud === keyOutOfStockKey) {
                el.outOfStock = outOfStockValue;
            }
        });
    }
};

export const updateCategoriesOutOfStock = (
    category,
    redirectItem,
    outOfStockValue,
    keyOutOfStockKey,
    categories
) => {
    if (redirectItem !== undefined && category !== undefined) {
        const referenceRedirectItem = redirectItem[keyOutOfStockKey].ref;
        const categoryParentIuud = redirectItem[keyOutOfStockKey].parent;

        category.listProduct?.map((cat) => {
            if (cat.type === "categories") {
                cat.isRedirect &&
                    cat.ref === referenceRedirectItem &&
                    updateItemsOutOfStock(
                        cat,
                        outOfStockValue,
                        keyOutOfStockKey,
                        categories,
                        categoryParentIuud
                    );
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                updateCategoriesOutOfStock(cat);
            } else {
                if (cat.ref === referenceRedirectItem) {
                    cat = updateItemsOutOfStock(
                        cat,
                        outOfStockValue,
                        keyOutOfStockKey,
                        categories,
                        categoryParentIuud
                    );
                }
                return cat;
            }
        });
    }
};
export function getUrlImage(productImage, url) {
    let imgSrc = `${productImage.slice(0, -5)}${url}${productImage.slice(-5)}`;

    if (
        productImage.includes("svg") ||
        productImage.includes("no-pictures") ||
        productImage.includes("picture")
    ) {
        return "./images/nopicture.png";
    }
    return imgSrc;
}

export const refactorAdvancedDisplay = (advancedDisplay) => {
    let kioskOption = advancedDisplay?.dflt?.salesSupport?.[1];
    kioskOption = kioskOption !== undefined ? transformObject(kioskOption) : {};

    return kioskOption;
};
const transformObject = (kioskOption) => {
    let result = {};
    for (let language in kioskOption) {
        let values = kioskOption[language];
        if (values.length > 0) {
            result[getLanguagesAbreviation(language)] = values;
        }
    }
    return result;
};
export function getLanguagesAbreviation(lang) {
    let language = "French";
    switch (lang) {
        case "es":
            language = "Spanish";
            break;
        case "fr":
            language = "French";
            break;
        case "nl":
            language = "German";
            break;
        case "en":
            language = "English";
            break;
        case "pt":
            language = "Portuguese";
            break;
        case "it":
            language = "Italian";
            break;
        default:
            language = "French";
    }
    return language;
}

export function extractIuudValues(obj) {
    let result = [];

    Object.keys(obj).forEach((key) => {
        if (key === "iuud") {
            result.push(obj[key]);
        } else if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            key !== "workflow" &&
            key !== "path"
        ) {
            result = result.concat(extractIuudValues(obj[key]));
        }
    });

    return result;
}

export function productHaveItemOutOfStock(data, items) {
    let isHaveOutOfStockComposition = false;
    const listUuid = extractIuudValues(data);
    for (var i = 0; i < listUuid.length; i++) {
        if (items[listUuid[i]]?.outOfStock === true) {
            isHaveOutOfStockComposition = true;
            break;
        }
    }

    return isHaveOutOfStockComposition;
}
